import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { checkIsAuthenticated, getCurrentUser } from "@bridge5/helpers";
import { actions } from "./actions";

export const useControlHeaderBar = () => {
  const [isSubappHiddenHeader, setIsSubappHiddenHeader] = useState(false);
  const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalStorage("refreshToken", "", { raw: true });
  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage("accessToken", "", { raw: true });
  const [currentUser, setCurrentUser, removeCurrentUser] = useLocalStorage("currentUser", "", { raw: true });

  const isHiddenHeader = isSubappHiddenHeader;

  const isAuthenticated = checkIsAuthenticated(accessToken);

  const isHeaderShow = isAuthenticated && !isHiddenHeader;

  useEffect(() => {
    actions.onGlobalStateChange((state, prev) => {
      console.log("actions.onGlobalStateChange", state);
      setIsSubappHiddenHeader(state.isSubappHiddenHeader);
      if (state.refreshToken !== prev.refreshToken) {
        setRefreshToken(state.refreshToken);
      }
      if (state.accessToken !== prev.accessToken) {
        setAccessToken(state.accessToken);
      }
      if (state.currentUser !== prev.currentUser) {
        setCurrentUser(state.currentUser);
      }
    }, true);
  }, [setAccessToken, setCurrentUser, setRefreshToken, currentUser]);

  return {
    isHiddenHeader,
    refreshToken,
    accessToken,
    currentUser: getCurrentUser(accessToken),
    isAuthenticated,
    removeRefreshToken,
    removeCurrentUser,
    removeAccessToken,
    isHeaderShow,
  };
};

export const hideHeaderCenter = {
  hide() {
    console.log("hide run...");
    actions.setGlobalState({
      isSubappHiddenHeader: true,
    });
  },
  show() {
    actions.setGlobalState({
      isSubappHiddenHeader: false,
    });
  },
};
