const dispatch = action => {
  if (!window.store) return;

  return window.store.dispatch(action);
};

const subscribe = fn => {
  if (!window.store) return;

  return window.store.subscribe(fn);
};

const getState = () => {
  if (!window.store) return {};

  return window.store.getState();
};

const plugins = {
  dispatch,
  subscribe,
  getState,
};

export default plugins;
