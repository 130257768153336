import React from "react";
import { styled } from "@bridge5/styles";
import { ErrorMessage as FormikErrorMessage } from "formik";

const Custom = styled("div", {
	verticalAlign: "middle",
	color: "$red9",
	cursor: "default",
	fontSize: "12px",
	my: "$1",
});

export const ErrorMessage = ({ component = Custom, ...props }) => (
	<FormikErrorMessage component={component} {...props} />
);

export default ErrorMessage;
