import React from "react";
import {
  Box,
  Grid,
  FieldLabel,
  TextField,
  Button,
  DialogClose,
  DialogTitle,
  DialogFooter,
  DialogChild,
  Alert,
  Text,
} from "@bridge5/elements";
import { Formik } from "formik";
import { ErrorMessage } from "#/components";

const checkHasErrors = (requestErrors = {}) => Object.keys(requestErrors).length !== 0;

export const ResetsPwdForm = ({
  onSubmit = () => {},
  requestErrors = {},
  initialValues = {
    oldpassword: "",
    password: "",
    repassword: "",
  },
}) => (
  <DialogChild>
    {({ context }) => (
      <Box css={{ bc: "white", borderRadius: "$3" }}>
        <DialogTitle>修改用户密码</DialogTitle>
        <Formik
          initialValues={initialValues}
          validate={values => {
            const errors = {};
            if (values.oldpassword.length < 8) {
              errors.oldpassword = "请输入当前使用密码，用于校验修改权限。";
            }
            if (values.password.length < 8) {
              errors.password = "新密码长度须大于8位且包含数字和字母。";
            }
            if (values.password !== values.repassword) {
              errors.repassword = "新密码不匹配，请仔细检查输入。";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              onSubmit(values);
              setSubmitting(false);
              if (!checkHasErrors(requestErrors)) {
                context.onOpenChange(false);
              }
            }, 1000);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              {checkHasErrors(requestErrors) && !checkHasErrors(errors) && (
                <Box css={{ pa: "$2", width: "400px" }}>
                  <Alert variant="red">
                    <Text css={{ lineHeight: "17px" }}>
                      {requestErrors?.code}: {requestErrors?.message}
                    </Text>
                  </Alert>
                </Box>
              )}
              <Grid columns={1} gap={2} css={{ pa: "$2", width: "400px" }}>
                <FieldLabel htmlFor="oldpassword" label="旧密码 (当前使用密码)*">
                  <TextField
                    state={errors.oldpassword && "invalid"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.oldpassword}
                    type="password"
                    name="oldpassword"
                    size="2"
                    autoComplete="off"
                  />
                  <ErrorMessage name="oldpassword" />
                </FieldLabel>
                <FieldLabel htmlFor="password" label="新密码 (长度须大于8位且包含数字和字母)*">
                  <TextField
                    state={errors.password && "invalid"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    type="password"
                    name="password"
                    size="2"
                    autoComplete="off"
                  />
                  <ErrorMessage name="password" />
                </FieldLabel>
                <FieldLabel htmlFor="repassword" label="确认新密码*">
                  <TextField
                    state={errors.repassword && "invalid"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.repassword}
                    type="password"
                    name="repassword"
                    size="2"
                    autoComplete="off"
                  />
                  <ErrorMessage name="repassword" />
                </FieldLabel>
              </Grid>
              <DialogFooter>
                <DialogClose asChild>
                  <Button css={{ mx: "$2" }} size="2">
                    取消
                  </Button>
                </DialogClose>
                <DialogClose asChild isSubmitButton>
                  <Button variant="red" size="2" disabled={isSubmitting}>
                    确认
                  </Button>
                </DialogClose>
              </DialogFooter>
            </form>
          )}
        </Formik>
      </Box>
    )}
  </DialogChild>
);

export default ResetsPwdForm;
