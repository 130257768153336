const getBoolean = str => (str === undefined || str === "false" || str === false ? false : true);

export const B5A_PRIORITY = getBoolean(
  process.env.REACT_APP_B5A_PRIORITY || window.__INITIAL_CONFIG__?.B5A_PRIORITY || false
);

export const API_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT || window.__INITIAL_CONFIG__?.API_ENDPOINT || "http://localhost:4000";

export const APP_CUSTOMER = process.env.REACT_APP_APP_CUSTOMER || window.__INITIAL_CONFIG__?.APP_CUSTOMER || "bridge5";

export const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT || `//${window.location.origin.split("//")?.[1]}`;

export const API_REPORTSET_ENDPOINT =
  process.env.NEXT_PUBLIC_APP_REPORTSET_ENDPOINT | window.__INITIAL_CONFIG__?.API_REPORTSET_ENDPOINT;

export const isUnifiedEndpoint = APP_ENDPOINT !== undefined;

export const SUBAPP_WORKBENCH =
  process.env.REACT_APP_SUBAPP_WORKBENCH ||
  window.__INITIAL_CONFIG__?.SUBAPP_WORKBENCH ||
  `${APP_ENDPOINT}/subapp/workbench/`;

export const SUBAPP_AUTHORIZE =
  process.env.REACT_APP_SUBAPP_AUTHORIZE ||
  window.__INITIAL_CONFIG__?.SUBAPP_AUTHORIZE ||
  `${APP_ENDPOINT}/subapp/authorize/`;

export const SUBAPP_MIXBUDGET =
  process.env.REACT_APP_SUBAPP_MIXBUDGET ||
  window.__INITIAL_CONFIG__?.SUBAPP_MIXBUDGET ||
  `${APP_ENDPOINT}/subapp/mixbudget/`;

export const SUBAPP_REFORMDATAS =
  process.env.REACT_APP_SUBAPP_REFORMDATAS ||
  window.__INITIAL_CONFIG__?.SUBAPP_REFORMDATAS ||
  `${APP_ENDPOINT}/subapp/reformdatas/`;

export const SUBAPP_PROTFOLIO =
  process.env.REACT_APP_SUBAPP_PROTFOLIO ||
  window.__INITIAL_CONFIG__?.SUBAPP_PROTFOLIO ||
  `${APP_ENDPOINT}/subapp/portfolio/`;

export const SUBAPP_DEALSTRUCT =
  process.env.REACT_APP_SUBAPP_DEALSTRUCT ||
  window.__INITIAL_CONFIG__?.SUBAPP_DEALSTRUCT ||
  `${APP_ENDPOINT}/subapp/dealstruct/`;

export const SUBAPP_BATCHWISE =
  process.env.REACT_APP_SUBAPP_BATCHWISE ||
  window.__INITIAL_CONFIG__?.SUBAPP_BATCHWISE ||
  `${APP_ENDPOINT}/subapp/batchwise/`;

export const SUBAPP_BACKSTAEG =
  process.env.REACT_APP_SUBAPP_BACKSTAEG ||
  window.__INITIAL_CONFIG__?.SUBAPP_BACKSTAEG ||
  `${APP_ENDPOINT}/subapp/backstage/`;

export const SUBAPP_SYSCONSOLE =
  process.env.REACT_APP_SUBAPP_SYSCONSOLE ||
  window.__INITIAL_CONFIG__?.SUBAPP_SYSCONSOLE ||
  `${APP_ENDPOINT}/subapp/sysconsole/`;

export const SUBAPP_WORKSTAGE =
  process.env.REACT_APP_SUBAPP_WORKSTAGE ||
  window.__INITIAL_CONFIG__?.SUBAPP_WORKSTAGE ||
  `${APP_ENDPOINT}/subapp/workstage/`;

export const SUBAPP_ACTUALDATAS =
  process.env.REACT_APP_SUBAPP_ACTUALDATAS ||
  window.__INITIAL_CONFIG__?.SUBAPP_ACTUALDATAS ||
  `${APP_ENDPOINT}/subapp/actualdatas/`;

export const SUBAPP_CAPITALDATAS =
  process.env.REACT_APP_SUBAPP_CAPITALDATAS ||
  window.__INITIAL_CONFIG__?.SUBAPP_CAPITALDATAS ||
  `${APP_ENDPOINT}/subapp/capitaldatas/`;

export const SUBAPP_FOSUNDATAS =
  process.env.REACT_APP_SUBAPP_FOSUNDATAS ||
  window.__INITIAL_CONFIG__?.SUBAPP_FOSUNDATAS ||
  `${APP_ENDPOINT}/subapp/fosundatas/`;

export const SUBAPP_SCREENSHOTS =
  process.env.REACT_APP_SUBAPP_SCREENSHOTS ||
  window.__INITIAL_CONFIG__?.SUBAPP_SCREENSHOTS ||
  `${APP_ENDPOINT}/subapp/screenshots/`;

export const SUBAPP_VSREPORTS =
  process.env.REACT_APP_SUBAPP_VSREPORTS ||
  window.__INITIAL_CONFIG__?.SUBAPP_VSREPORTS ||
  `${APP_ENDPOINT}/subapp/vsreports/`;

export const SUBAPP_NONSTANDARD =
  process.env.REACT_APP_SUBAPP_NONSTANDARD ||
  window.__INITIAL_CONFIG__?.SUBAPP_NONSTANDARD ||
  `${APP_ENDPOINT}/subapp/nonstandard/`;

export const SUBAPP_ACCREDITION =
  process.env.REACT_APP_SUBAPP_ACCREDITION ||
  window.__INITIAL_CONFIG__?.SUBAPP_ACCREDITION ||
  `${APP_ENDPOINT}/subapp/accredition/`;

export const DEPOPT04 = getBoolean(process.env.REACT_APP_DEPOPT04 || window.__INITIAL_CONFIG__?.DEPOPT04 || false);

export const DEPOPT05 = getBoolean(process.env.REACT_APP_DEPOPT05 || window.__INITIAL_CONFIG__?.DEPOPT05 || false);

const initialConfig = {
  DEPOPT04,
  DEPOPT05,
  API_ENDPOINT,
  APP_CUSTOMER,
  APP_ENDPOINT,
  API_REPORTSET_ENDPOINT,
  B5A_PRIORITY,
  SUBAPP_AUTHORIZE,
  SUBAPP_WORKBENCH,
  SUBAPP_MIXBUDGET,
  SUBAPP_PROTFOLIO,
  SUBAPP_BACKSTAEG,
  SUBAPP_BATCHWISE,
  SUBAPP_CAPITALDATAS,
  SUBAPP_SYSCONSOLE,
  SUBAPP_REFORMDATAS,
  SUBAPP_ACTUALDATAS,
  SUBAPP_SCREENSHOTS,
  SUBAPP_FOSUNDATAS,
  SUBAPP_WORKSTAGE,
  SUBAPP_VSREPORTS,
  SUBAPP_NONSTANDARD,
  SUBAPP_ACCREDITION,
};

console.log(initialConfig);

export default initialConfig;
