import React from "react";

export const AnshiLogo = ({ color, size = 42, onClick = () => {}, ...otherProps }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140.69 195.79"
    onClick={onClick}
    {...otherProps}
  >
    <g id="图层_2" dataName="图层 2">
      <g id="图层_1-2" dataName="图层 1">
        <g id="Fill_1" dataName="Fill 1">
          <path fill="#ac1f24" d="M70.35,0V49.29L113,24.65Z" />
        </g>
        <g id="Fill_2" dataName="Fill 2">
          <path fill="#c8c9c9" d="M27.66,24.65,70.35,49.29V0Z" />
        </g>
        <g id="Fill_3" dataName="Fill 3">
          <path fill="#9e9f9f" d="M27.66,73.94,70.35,49.3,27.66,24.65Z" />
        </g>
        <g id="Fill_4" dataName="Fill 4">
          <path fill="#b4b4b4" d="M70.35,49.29,113,73.94,70.35,98.58Z" />
        </g>
        <g id="Fill_5" dataName="Fill 5">
          <path fill="#c8c9c9" d="M27.66,73.94,70.35,98.58V49.29Z" />
        </g>
        <g id="Fill_6" dataName="Fill 6">
          <path fill="#9e9f9f" d="M113,123.23,70.35,98.59,113,73.94Z" />
        </g>
        <g id="Fill_7" dataName="Fill 7">
          <path fill="#b4b4b4" d="M70.35,147.87,113,123.23,70.35,98.58Z" />
        </g>
        <g id="Fill_8" dataName="Fill 8">
          <path fill="#ac1f24" d="M27.66,123.23l42.69,24.64V98.58Z" />
        </g>
        <g id="Group_17" dataName="Group 17">
          <g id="Fill_9" dataName="Fill 9">
            <path
              fill="#585757"
              d="M15.6,195.76a14.6,14.6,0,0,1-11-4.9A17.24,17.24,0,0,1,0,178.63a17.64,17.64,0,0,1,4.48-12.42,14.31,14.31,0,0,1,10.88-4.84,14,14,0,0,1,5.52,1.11,15.28,15.28,0,0,1,4.77,3.34v-3.61h8.21v32.72H25.66v-3.46a16,16,0,0,1-4.81,3.29A13.51,13.51,0,0,1,15.6,195.76ZM17,169a8.34,8.34,0,0,0-6.34,2.69,9.69,9.69,0,0,0-2.51,6.9,9.83,9.83,0,0,0,2.57,7,8.36,8.36,0,0,0,6.31,2.74,8.5,8.5,0,0,0,6.41-2.69A9.91,9.91,0,0,0,26,178.51a9.62,9.62,0,0,0-2.54-6.92A8.66,8.66,0,0,0,17,169Z"
            />
          </g>
          <g id="Fill_11" dataName="Fill 11">
            <path
              fill="#585757"
              d="M38.17,162.21h8.21V166a13.89,13.89,0,0,1,4.68-3.46,13.7,13.7,0,0,1,5.61-1.14,10.4,10.4,0,0,1,5.54,1.5,10.84,10.84,0,0,1,4,4.39,13.31,13.31,0,0,1,4.8-4.39,13.18,13.18,0,0,1,6.21-1.5A11.51,11.51,0,0,1,83.33,163a9.43,9.43,0,0,1,3.81,4.24,22.73,22.73,0,0,1,1.16,8.5v19.18H80V178.33a14.19,14.19,0,0,0-1.38-7.53,4.74,4.74,0,0,0-4.15-2,6.29,6.29,0,0,0-3.77,1.2,7,7,0,0,0-2.48,3.34,20.64,20.64,0,0,0-.82,6.81V195H59.16V179.07a22.27,22.27,0,0,0-.65-6.35,5.72,5.72,0,0,0-1.94-2.93,5.12,5.12,0,0,0-3.14-1,6.14,6.14,0,0,0-3.72,1.22,7.29,7.29,0,0,0-2.5,3.41,21,21,0,0,0-.83,6.91v14.56H38.17Z"
            />
          </g>
          <g id="Fill_13" dataName="Fill 13">
            <path
              fill="#585757"
              d="M114.57,166.78l-5.08,5.08a8.6,8.6,0,0,0-5.63-3.06,3.53,3.53,0,0,0-2.16.58,1.75,1.75,0,0,0-.78,1.46,1.8,1.8,0,0,0,.49,1.22,9.39,9.39,0,0,0,2.45,1.52l3,1.5a17.78,17.78,0,0,1,6.53,4.79,10,10,0,0,1-1.43,13,12.26,12.26,0,0,1-8.59,2.92A13.65,13.65,0,0,1,92,190.18l5.06-5.5a11.58,11.58,0,0,0,3.38,2.72,7.47,7.47,0,0,0,3.44,1,4.15,4.15,0,0,0,2.62-.78,2.3,2.3,0,0,0,1-1.81c0-1.26-1.2-2.49-3.58-3.7l-2.77-1.38q-7.95-4-7.94-10a8.69,8.69,0,0,1,3-6.63,10.86,10.86,0,0,1,7.64-2.75,13.3,13.3,0,0,1,10.77,5.41"
            />
          </g>
          <g id="Fill_15" dataName="Fill 15">
            <path
              fill="#ac1f24"
              d="M140.09,166.78,135,171.86a8.62,8.62,0,0,0-5.62-3.06,3.54,3.54,0,0,0-2.17.58,1.75,1.75,0,0,0-.78,1.46,1.8,1.8,0,0,0,.49,1.22,9.39,9.39,0,0,0,2.45,1.52l3,1.5a17.78,17.78,0,0,1,6.53,4.79,10,10,0,0,1-1.43,13,12.26,12.26,0,0,1-8.59,2.92,13.65,13.65,0,0,1-11.43-5.59l5.06-5.5a11.58,11.58,0,0,0,3.38,2.72,7.5,7.5,0,0,0,3.44,1,4.15,4.15,0,0,0,2.62-.78,2.3,2.3,0,0,0,1-1.81q0-1.89-3.58-3.7l-2.77-1.38q-7.94-4-7.94-10a8.69,8.69,0,0,1,3-6.63,10.87,10.87,0,0,1,7.65-2.75,13.28,13.28,0,0,1,10.76,5.41"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AnshiLogo;
