import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { createRoot } from "react-dom/client";
import { registerMicroApps, runAfterFirstMounted, start, setDefaultMountApp, initGlobalState } from "qiankun";
import renderApplication from "./renderApplication";
import renderSubapps from "./renderSubapps";

nprogress.configure({ showSpinner: false });

registerMicroApps(renderSubapps, {
  beforeLoad: [
    app => {
      nprogress.start();
      console.log("[LifeCycle] before load %c%s", "color: green;", app.name);
      return Promise.resolve();
    },
  ],
  beforeMount: [
    app => {
      nprogress.done();
      console.log("[LifeCycle] before mount %c%s", "color: green;", app.name);
      return Promise.resolve();
    },
  ],
  afterUnmount: [
    app => {
      console.log("[LifeCycle] after unmount %c%s", "color: green;", app.name);
    },
  ],
});

initGlobalState({
  isSubappHiddenHeader: false,
  refreshToken: window.localStorage.getItem("refreshToken") || undefined,
  accessToken: window.localStorage.getItem("accessToken") || undefined,
  currentUser: window.localStorage.getItem("currentUser") || {},
  locale: window.localStorage.getItem("locale") || "zh",
  customer: "bridge5",
});

setDefaultMountApp("/authorize/login");

start();

const container = document.getElementById("mainapp");
const root = createRoot(container);

renderApplication(root);

runAfterFirstMounted(() => {
  console.log("[MainApp] first app mounted");
});
