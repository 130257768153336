import React from "react";
import { includes } from "lodash-es";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Avatar,
  Tooltip,
  Dialog,
  DialogTrigger,
  DialogContent,
  Image,
  Button,
} from "@bridge5/elements";
import { initialConfig } from "#/commons";

import ResetsPwdForm from "./ResetsPwdForm";
import PasswordKIcon from "./PasswordKIcon";
import LogoutSysIcon from "./LogoutSysIcon";
import PortfolioIcon from "./PortfolioIcon";

import AnshiLogo from "./AnshiLogo";
import FosunLogo from "./FosunLogo.png";
import KaideLogo from "./KaideLogo.svg";
import VankeLogo from "./VankeLogo.svg";
import HejingLogo from "./HejingLogo.png";
import AmssLogo from "./AmssLogo";

export function CustomHeader({
  locale = "zh",
  currentUser = {},
  isSupervisor = false,
  showPortfolio = false,
  showWorkstage = false,
  showBackstage = false,
  showLocaleBtn = false,
  showAccredition = false,
  showCurrentUsername = false,
  onLogoutClick = () => {},
  onLocaleChange = () => {},
  onGotoPortfolio = () => {},
  onGotoWorkstage = () => {},
  onGotoBackstage = () => {},
  onGotoPortfolioMagt = () => {},
  onChangePwdSubmit = () => {},
  onGotoAccredition = () => {},
  requestErrors = {},
}) {
  const isRoleSupervisor = currentUser?.roles?.includes("supervisor");
  const isRoleDirector = currentUser?.roles?.includes("director");
  const isRoleWorker = currentUser?.roles?.includes("worker");

  const hideButton = includes(window.location.href, "authorize");

  return (
    <Box
      css={{
        position: "absolute",
        left: "0",
        width: "100%",
        py: "9px",
        px: "$2",
        backgroundColor: "$loContrast",
        borderBottom: "1px solid $canvas",
      }}
    >
      <Flex css={{ alignItems: "center", justifyContent: "space-between", margin: "0 auto" }}>
        <Flex css={{ alignItems: "center" }}>
          {initialConfig?.B5A_PRIORITY ? (
            initialConfig?.API_ENDPOINT?.includes("delta") ? (
              <AmssLogo />
            ) : (
              <Box css={{ mr: "$2", pt: "$1", height: "48px" }}>
                <AmssLogo />
              </Box>
            )
          ) : (
            <Box css={{ mr: "$2", height: "48px" }}>
              {initialConfig.APP_CUSTOMER === "fosun" ? (
                <Image src={FosunLogo} alt="FosunLogo" css={{ height: "48px" }} />
              ) : initialConfig.APP_CUSTOMER === "capitaland" || initialConfig.APP_CUSTOMER === "kaide" ? (
                <Image src={KaideLogo} alt="KaideLogo" css={{ height: "48px" }} />
              ) : initialConfig.APP_CUSTOMER === "hejing" || initialConfig.APP_CUSTOMER === "kwggroup" ? (
                <Image src={HejingLogo} alt="HejingLogo" css={{ height: "48px" }} />
              ) : initialConfig.APP_CUSTOMER === "vanke" ? (
                <Box css={{ pt: "15px", height: "48px" }}>
                  <Image src={VankeLogo} alt="VankeLogo" css={{ height: "20px" }} />
                </Box>
              ) : (
                <Box css={{ pt: "$1", height: "48px" }}>
                  <AnshiLogo />
                </Box>
              )}
            </Box>
          )}
          {showCurrentUsername && (
            <Text css={{ fontWeight: 500 }}>
              {locale === "zh" ? "欢迎使用" : "Welcome"}，{currentUser?.username}
            </Text>
          )}
        </Flex>
        <Flex css={{ alignItems: "center" }}>
          {showAccredition && (
            <Button css={{ fontSize: "13px", ml: "2px" }} onClick={onGotoAccredition}>
              授权管理
            </Button>
          )}
          {showPortfolio && isRoleSupervisor && !hideButton && (
            <React.Fragment>
              <Button css={{ fontSize: "13px", ml: "2px" }} onClick={onGotoBackstage}>
                项目管理
              </Button>
              <Button css={{ fontSize: "13px", ml: "2px" }} onClick={onGotoPortfolio}>
                资产组合
              </Button>
              <Box css={{ ml: "$2", pt: "$1" }}>
                <Tooltip content="资产组合管理" side="bottom" align="center">
                  <IconButton onClick={onGotoPortfolioMagt}>
                    <PortfolioIcon size={28} />
                  </IconButton>
                </Tooltip>
              </Box>
            </React.Fragment>
          )}
          {showPortfolio && !isRoleSupervisor && isRoleWorker && !hideButton && (
            <Button css={{ fontSize: "13px", ml: "2px" }} onClick={onGotoWorkstage}>
              项目管理
            </Button>
          )}
          {showPortfolio && !isRoleSupervisor && isRoleDirector && !hideButton && (
            <Button css={{ fontSize: "13px", ml: "2px" }} onClick={onGotoPortfolio}>
              资产组合
            </Button>
          )}
          {showLocaleBtn && (
            <Box css={{ mx: "$2" }}>
              <Tooltip content="切换语言" side="bottom" align="center">
                <IconButton onClick={() => onLocaleChange(locale)}>
                  <Text css={{ fontSize: "17px", color: "#596970", fontWeight: 500 }}>
                    {locale === "zh" ? "英" : "中"}
                  </Text>
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Dialog>
            <DialogTrigger asChild>
              <Box css={{ mx: "$2" }}>
                <Tooltip content="修改密码" side="bottom" align="center">
                  <IconButton>
                    <PasswordKIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </DialogTrigger>
            <DialogContent>
              <ResetsPwdForm onSubmit={onChangePwdSubmit} requestErrors={requestErrors} />
            </DialogContent>
          </Dialog>
          <Box css={{ mr: "$2" }}>
            <Tooltip content="退出系统" side="bottom" align="center">
              <IconButton onClick={onLogoutClick}>
                <LogoutSysIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Tooltip content={`邮箱：${currentUser?.email}`} side="bottom" align="center">
            <Avatar shape="square" variant="gray" fallback={currentUser?.username[0]} />
          </Tooltip>
        </Flex>
      </Flex>
    </Box>
  );
}
