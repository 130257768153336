import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { useMutation } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import { Box } from "@bridge5/elements";
import {
  checkIsAuthenticated,
  checkHasRefreshToken,
  replaceLocation,
  getCurrentApplet,
  getCurrentUser,
} from "@bridge5/helpers";
import { CustomHeader } from "#/components";
import { AuthService } from "#/services";
import { initialConfig } from "#/commons";

import { setGlobalStyles } from "#/styles";

const queryClient = new QueryClient();

const MainApplication = () => {
  // eslint-disable-next-line no-unused-vars
  const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalStorage("refreshToken", "", { raw: true });
  // eslint-disable-next-line no-unused-vars
  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage("accessToken", "", { raw: true });

  const [locale, setLocale] = useLocalStorage("locale", "zh", { raw: true });

  const hasRefreshToken = checkHasRefreshToken(refreshToken);
  const isAuthenticated = checkIsAuthenticated(accessToken);
  const currentApplet = getCurrentApplet();

  const isSubappHiddenHeader = ["workbench", "reformdatas", "screenshots"].includes(currentApplet);

  const [requestErrors, setRequestErrors] = useState({});

  useEffect(() => {
    if (!hasRefreshToken) {
      removeRefreshToken();
    }

    if (!isAuthenticated) {
      removeAccessToken();
    }
  }, [hasRefreshToken, isAuthenticated, removeRefreshToken, removeAccessToken]);

  // useEffect(() => {
  //   if (initialConfig?.APP_CUSTOMER === "bridge5" && initialConfig?.API_ENDPOINT?.includes("delta")) {
  //     document.title = "AMSS资产管理系统";
  //   }
  // }, []);

  const { mutate: mutateChangePwd } = useMutation(AuthService.changePwd, {
    onSuccess: (data, variables, context) => {
      // console.log(data);
      if (data?.errors) {
        setRequestErrors(data?.errors);
      } else {
        setRequestErrors({});
      }
    },
  });

  const { mutate: mutateLogout } = useMutation(AuthService.logout, {
    onSuccess: (data, variables, context) => {
      replaceLocation("/authorize/login");
      removeRefreshToken();
      removeAccessToken();
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });

  const currentUser = isAuthenticated && getCurrentUser();

  const isSupervisor = currentUser?.roles?.includes("supervisor");

  const showCurrentUsername = [
    "workstage",
    "backstage",
    "authorize",
    "sysconsole",
    "actualdatas",
    "capitaldatas",
    "mixbudget",
    "nonstandard",
  ].includes(currentApplet);

  // 因为saas 使用的customer也为bridge5,所以为了区分delta和saas环境 增加了includes的判断
  const showPortfolio =
    initialConfig?.APP_CUSTOMER === "fosun" ||
    (initialConfig?.APP_CUSTOMER === "bridge5" && initialConfig?.API_ENDPOINT?.includes("delta"));

  return (
    <Box css={{ overflow: "hidden", position: "relative" }}>
      <Box css={{ zIndex: 70, position: "fixed", width: "100%", bc: "#ffffff" }}>
        {!isSubappHiddenHeader && isAuthenticated && (
          <CustomHeader
            showLocaleBtn={false}
            showWorkstage
            showBackstage
            showCurrentUsername={showCurrentUsername}
            showAccredition={initialConfig.APP_CUSTOMER === "fosun" && isSupervisor}
            showPortfolio={showPortfolio}
            onGotoAccredition={() => replaceLocation("/accredition")}
            onGotoPortfolioMagt={() => replaceLocation("/portfolio/amss")}
            onGotoPortfolio={() => replaceLocation("/portfolio/analysis")}
            onGotoWorkstage={() => replaceLocation("/workstage")}
            onGotoBackstage={() => replaceLocation("/backstage")}
            onChangePwdSubmit={values => mutateChangePwd(values)}
            onLocaleChange={values => setLocale(values === "zh" ? "en" : "zh")}
            onLogoutClick={() => mutateLogout()}
            requestErrors={requestErrors}
            currentUser={currentUser}
            locale={locale}
          />
        )}
      </Box>
      <Box css={{ minHeight: "100%" }}>
        <div style={{ height: "100%" }} id="subapp" />
      </Box>
    </Box>
  );
};

const renderApplication = root => {
  setGlobalStyles();

  root.render(
    <QueryClientProvider client={queryClient}>
      <MainApplication />
      {process.env.NODE_ENV !== "production" && <ReactQueryDevtools position="bottom-right" />}
    </QueryClientProvider>
  );
};

export default renderApplication;
