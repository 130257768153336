class Shared {
  // eslint-disable-next-line class-methods-use-this
  getRefreshToken() {
    return localStorage.getItem("refreshToken") || "";
  }

  // eslint-disable-next-line class-methods-use-this
  getAccessToken() {
    return localStorage.getItem("accessToken") || "";
  }

  // eslint-disable-next-line class-methods-use-this
  getCurrentUser() {
    const value = localStorage.getItem("currentUser");
    return JSON.parse(value);
  }

  // eslint-disable-next-line class-methods-use-this
  setRefreshToken(token) {
    localStorage.setItem("refreshToken", token);
  }

  // eslint-disable-next-line class-methods-use-this
  setAccessToken(token) {
    localStorage.setItem("accessToken", token);
  }

  // eslint-disable-next-line class-methods-use-this
  setCurrentUser(user) {
    localStorage.setItem("currentUser", JSON.stringify(user));
  }

  // eslint-disable-next-line class-methods-use-this
  removeTokens() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("currentUser");
  }
}

class SharedModule {
  static shared = new Shared();

  static overloadShared(shared) {
    SharedModule.shared = shared;
  }

  static getShared() {
    return SharedModule.shared;
  }
}

export default SharedModule;
