import { initialConfig, hideHeaderCenter, getVaildSubapp } from "#/commons";

const allSubapps = [
  {
    name: "amss-authorize",
    entry: initialConfig.SUBAPP_AUTHORIZE,
    activeRule: "/authorize",
  },
  {
    name: "amss-backstage",
    entry: initialConfig.SUBAPP_BACKSTAEG,
    activeRule: "/backstage",
  },
  {
    name: "amss-sysconsole",
    entry: initialConfig.SUBAPP_SYSCONSOLE,
    activeRule: "/sysconsole",
  },
  {
    name: "amss-portfolio",
    entry: initialConfig.SUBAPP_PROTFOLIO,
    activeRule: "/portfolio",
  },
  {
    name: "amss-reformdatas",
    entry: initialConfig.SUBAPP_REFORMDATAS,
    activeRule: "/reformdatas",
  },
  {
    name: "amss-actualdatas",
    entry: initialConfig.SUBAPP_ACTUALDATAS,
    activeRule: "/actualdatas",
  },
  {
    name: "amss-workbench",
    entry: initialConfig.SUBAPP_WORKBENCH,
    activeRule: "/workbench",
  },
  {
    name: "amss-workstage",
    entry: initialConfig.SUBAPP_WORKSTAGE,
    activeRule: "/workstage",
  },
  {
    name: "amss-batchwise",
    entry: initialConfig.SUBAPP_BATCHWISE,
    activeRule: "/batchwise",
  },
  {
    name: "amss-mixbudget",
    entry: initialConfig.SUBAPP_MIXBUDGET,
    activeRule: "/mixbudget",
  },
  {
    name: "amss-capitaldatas",
    entry: initialConfig.SUBAPP_CAPITALDATAS,
    activeRule: "/capitaldatas",
  },
  {
    name: "amss-fosundatas",
    entry: initialConfig.SUBAPP_FOSUNDATAS,
    activeRule: "/fosundatas",
  },
  {
    name: "amss-screenshots",
    entry: initialConfig.SUBAPP_SCREENSHOTS,
    activeRule: "/screenshots",
  },
  {
    name: "amss-vsreports",
    entry: initialConfig.SUBAPP_VSREPORTS,
    activeRule: "/vsreports",
  },
  {
    name: "amss-nonstandard",
    entry: initialConfig.SUBAPP_NONSTANDARD,
    activeRule: "/nonstandard",
  },
  {
    name: "amss-accredition",
    entry: initialConfig.SUBAPP_ACCREDITION,
    activeRule: "/accredition",
  },
];

const subapps = allSubapps.filter(item => getVaildSubapp(initialConfig.APP_CUSTOMER)?.includes(item?.name));

const apps = subapps.map(item => {
  return {
    ...item,
    container: "#subapp",
    props: {
      routerBase: item.activeRule,
      hideHeaderCenter,
    },
  };
});

export default apps;
