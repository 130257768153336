import { remove } from "lodash-es";
import initialConfig from "./initialConfig";

const getVaildSubapp = customer => {
  let subapps = [];

  if (customer === "fosun") {
    subapps = [
      "amss-authorize",
      "amss-sysconsole",
      "amss-backstage",
      "amss-portfolio",
      "amss-reformdatas",
      "amss-actualdatas",
      "amss-screenshots",
      "amss-workbench",
      "amss-workstage",
      "amss-vsreports",
      "amss-qareports",
      "amss-accredition",
    ];
  } else if (customer === "anshi" || customer === "bridge5") {
    if (initialConfig.DEPOPT04 || initialConfig.DEPOPT05) {
      subapps = ["amss-authorize", "amss-sysconsole", "amss-mixbudget"];
    } else {
      subapps = [
        "amss-authorize",
        "amss-sysconsole",
        "amss-backstage",
        "amss-portfolio",
        "amss-reformdatas",
        "amss-actualdatas",
        "amss-screenshots",
        "amss-workbench",
        "amss-workstage",
        "amss-vsreports",
        "amss-qareports",
      ];
    }
  } else if (customer === "kaide" || customer === "capitaland") {
    subapps = ["amss-authorize", "amss-sysconsole", "amss-mixbudget", "amss-capitaldatas"];
  } else if (customer === "hejing") {
    subapps = ["amss-authorize", "amss-sysconsole", "amss-nonstandard", "amss-mixbudget"];
  } else if (customer === "vanke") {
    subapps = ["amss-authorize", "amss-sysconsole", "amss-mixbudget"];
  } else {
    subapps = ["amss-authorize"];
  }

  if (
    !process.env.REACT_APP_SUBAPP_AUTHORIZE &&
    !window.__INITIAL_CONFIG__?.SUBAPP_AUTHORIZE &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-authorize");
  }

  if (
    !process.env.REACT_APP_SUBAPP_WORKBENCH &&
    !window.__INITIAL_CONFIG__?.SUBAPP_WORKBENCH &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-workbench");
  }

  if (
    !process.env.REACT_APP_SUBAPP_MIXBUDGET &&
    !window.__INITIAL_CONFIG__?.SUBAPP_MIXBUDGET &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-mixbudget");
  }

  if (
    !process.env.REACT_APP_SUBAPP_REFORMDATAS &&
    !window.__INITIAL_CONFIG__?.SUBAPP_REFORMDATAS &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-reformdatas");
  }

  if (
    !process.env.REACT_APP_SUBAPP_PROTFOLIO &&
    !window.__INITIAL_CONFIG__?.SUBAPP_PROTFOLIO &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-portfolio");
  }

  if (
    !process.env.REACT_APP_SUBAPP_DEALSTRUCT &&
    !window.__INITIAL_CONFIG__?.SUBAPP_DEALSTRUCT &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-dealstruct");
  }

  if (
    !process.env.REACT_APP_SUBAPP_BATCHWISE &&
    !window.__INITIAL_CONFIG__?.SUBAPP_BATCHWISE &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-batchwise");
  }

  if (
    !process.env.REACT_APP_SUBAPP_BACKSTAEG &&
    !window.__INITIAL_CONFIG__?.SUBAPP_BACKSTAEG &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-backstage");
  }

  if (
    !process.env.REACT_APP_SUBAPP_WORKSTAGE &&
    !window.__INITIAL_CONFIG__?.SUBAPP_WORKSTAGE &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-workstage");
  }

  if (
    !process.env.REACT_APP_SUBAPP_SYSCONSOLE &&
    !window.__INITIAL_CONFIG__?.SUBAPP_SYSCONSOLE &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-sysconsole");
  }

  if (
    !process.env.REACT_APP_SUBAPP_ACTUALDATAS &&
    !window.__INITIAL_CONFIG__?.SUBAPP_ACTUALDATAS &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-actualdatas");
  }

  if (
    !process.env.REACT_APP_SUBAPP_SCREENSHOTS &&
    !window.__INITIAL_CONFIG__?.SUBAPP_SCREENSHOTS &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-screenshots");
  }

  if (
    !process.env.REACT_APP_SUBAPP_CAPITALDATAS &&
    !window.__INITIAL_CONFIG__?.SUBAPP_CAPITALDATAS &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-capitaldatas");
  }

  if (
    !process.env.REACT_APP_SUBAPP_FOSUNDATAS &&
    !window.__INITIAL_CONFIG__?.SUBAPP_FOSUNDATAS &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-fosundatas");
  }

  if (
    !process.env.REACT_APP_SUBAPP_VSREPORTS &&
    !window.__INITIAL_CONFIG__?.SUBAPP_VSREPORTS &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-vsreport");
  }

  if (
    !process.env.REACT_APP_SUBAPP_NONSTANDARD &&
    !window.__INITIAL_CONFIG__?.SUBAPP_NONSTANDARD &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-nonstandard");
  }

  if (
    !process.env.REACT_APP_SUBAPP_ACCREDITION &&
    !window.__INITIAL_CONFIG__?.SUBAPP_ACCREDITION &&
    !window.__INITIAL_CONFIG__?.APP_ENDPOINT
  ) {
    remove(subapps, item => item === "amss-accredition");
  }

  return subapps;
};

export default getVaildSubapp;
