import { globalCss } from "@bridge5/styles";

export const setGlobalStyles = globalCss({
	"*": {
		margin: 0,
		padding: 0,
		"box-sizing": "border-box",
	},
	"*::before": {
		"box-sizing": "border-box",
	},
	"*::after": {
		"box-sizing": "border-box",
	},
	body: {
		margin: 0,
		padding: 0,
		backgroundColor: "#f4f4f4",
		"line-height": 1,
		" -webkit-font-smoothing": "antialiased",
		"-moz-osx-font-smoothing": "grayscale",
	},
	a: {
		"text-decoration": "none",
	},
	"#__next": {
		position: "relative",
		"z-index": 0,
	},
});
