import { request, SharedModule } from "#/commons";

const shared = SharedModule.getShared();

export const getProfile = () => {
  return request.get("/accounts/mine/profile");
};

export const getRefreshToken = (token = localStorage.getItem("refreshToken")) => {
  return request.post(`/accounts/auth/refresh-token`, {
    refreshToken: token,
  });
};

export const getRevokeToken = (token = localStorage.getItem("refreshToken")) => {
  return request.post("/accounts/auth/revoke-token", {
    refreshToken: token,
  });
};

export const changePwd = async payload => {
  try {
    console.log(payload);
    const response = await request.post(`/accounts/mine/change-password`, payload);

    console.log(response);

    if (response?.errors) {
      console.log("密码修改失败");
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const login = async payload => {
  try {
    const response = await request.post(`/accounts/auth/login`, payload);

    if (!response.errors) {
      const { accessToken, refreshToken } = response;
      shared.setRefreshToken(refreshToken);
      shared.setAccessToken(accessToken);
    } else {
      shared.removeTokens();
    }
    return response;
  } catch (err) {
    shared.removeTokens();
    console.log(err);
  }
};

export const logout = async () => {
  shared.removeTokens();
};
