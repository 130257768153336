import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import initialConfig from "./initialConfig";

const instance = axios.create({
  baseURL: initialConfig.API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  request => {
    request.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
    return request;
  },
  // eslint-disable-next-line arrow-body-style
  err => {
    return Promise.reject(err);
  },
);

instance.interceptors.response.use(reply => {
  if (reply?.data?.errors) {
    console.log("数据返回有错");
  }

  return reply;
});

const refreshAuthLogic = failedRequest =>
  instance
    .post(`/accounts/auth/refresh-token`, {
      refreshToken: localStorage.getItem("refreshToken"),
    })
    .then(tokenResponse => {
      localStorage.setItem("refreshToken", tokenResponse.refreshToken);
      localStorage.setItem("accessToken", tokenResponse.accessToken);
      failedRequest.response.config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
      return Promise.resolve();
    })
    .catch(() => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("currentUser");
    });

createAuthRefreshInterceptor(instance, refreshAuthLogic, {
  statusCodes: [401, 403],
});

export default instance;
