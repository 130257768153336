import React from "react";

const PortfolioIcon = ({ size = 30, onClick = () => {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 28 28" onClick={onClick}>
    <g transform="translate(14709 22342)">
      <rect width="28" height="28" transform="translate(-14709 -22342)" fill="none" />
      <path
        d="M54.994,47.745h-.622a.507.507,0,0,0,0,1.008h.974v.336h-.975a.507.507,0,0,0,0,1.008h.974v.689a.507.507,0,0,0,1.008,0V50.1h1.125a.507.507,0,0,0,0-1.009H56.354v-.336h1.125a.507.507,0,0,0,0-1.008h-.621L58.033,46.6a.451.451,0,0,0-.638-.638l-1.462,1.462-1.461-1.463a.467.467,0,0,0-.637,0,.446.446,0,0,0,0,.623l1.159,1.159Zm12.95,9.373a1.288,1.288,0,0,1,1.344,1.344v5.375a1.322,1.322,0,0,1-1.277,1.378H49.2a1.437,1.437,0,0,1-1.411-1.378V58.463a1.394,1.394,0,0,1,1.343-1.344V45.024a1.379,1.379,0,0,1,1.411-1.31H61.293a1.254,1.254,0,0,1,1.277,1.31V46.4h4.1a1.253,1.253,0,0,1,1.276,1.31v9.407Zm0,6.752V58.5H61.679a3.351,3.351,0,0,1-6.147,0h-6.4v5.375H67.944ZM50.475,45.057V57.151h5.677a.471.471,0,0,1,.471.388,2.028,2.028,0,0,0,3.964,0,.489.489,0,0,1,.488-.386h.151V45.057Zm13.337,2.688H62.569v9.407h1.343V47.745h-.1Zm1.445,9.407H66.6V47.745H65.257v9.407Zm-5.308-2.015a.673.673,0,0,0-.673-.672H52.557a.672.672,0,0,0,0,1.343h6.719a.663.663,0,0,0,.672-.672m-7.391-2.015h6.719a.672.672,0,1,0,0-1.344H52.557a.672.672,0,0,0,0,1.344"
        transform="translate(-14753.787 -22382.713)"
        fill="#596970"
      />
    </g>
  </svg>
);

export default PortfolioIcon;
